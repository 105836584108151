.form-container {
  animation: slideDown 300ms ease-out;
  animation-fill-mode: forwards;
}
@keyframes slideDown {
  0% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(150px);
  }
}
.form-container-minimise {
  animation: slideUp 200ms ease-in;
  animation-fill-mode: forwards;
}
@keyframes slideUp {
  0% {
    transform: translateY(150px);
  }
  100% {
    transform: translateY(0px);
    opacity: 0;
  }
}

/* Custom radio button styles */
.custom-radio:checked {
  background-color: #78ca49;
  border-color: #78ca49;
}

.custom-radio:checked::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #78ca49;
  position: relative;
}

/* Adjusting the radio button to show the green circle inside */
.custom-radio {
  appearance: none;
  background-color: #fff;
  border: 1px solid #d1d5db;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  display: inline-block;
  position: relative;
}

.custom-radio:checked {
  background-color: #78ca49;
  border-color: #78ca49;
}

.custom-radio:checked::after {
  content: "";
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

textarea {
  resize: none;
}
