@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("./fonts/fonts.css");
@import url("./components/herobg.css");

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
img {
  user-zoom: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.font-avertastd-bold {
  font-family: "AvertaStdBold", "Courier New", "Courier";
}
.font-avertastd-extrabold {
  font-family: "AvertaStdExtraBold", "Courier New", "Courier";
}
.font-avertastd-regular {
  font-family: "AvertaStdRegular", "Courier New", "Courier";
}
.font-avertastd-semibold {
  font-family: "AvertaStdSemiBold", "Courier New", "Courier";
}

.cta-button > button {
  transition: all 200ms ease-out;
}
@media screen and (min-width: 500px) {
  .cta-button > button:hover {
    background-color: #a7dd88;
    transition: all 200ms ease-in;
  }
}

.footer-container::before {
  position: absolute;
  top: -5px;
  content: " ";
  border-radius: 2px;
  width: 95%;
  height: 2px;
  background-color: #eeeded;
}
.footer-sub-container {
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
  flex-direction: column;
  gap: 0.3em;
}
@media screen and (max-width: 1000px) {
  .footer-container::before {
    width: 110%;
  }
}
@media screen and (max-width: 800px) {
  .footer-container::before {
    width: 100%;
  }
}
@media (max-width: 595px) {
  .container.footer-container {
    flex-direction: column;
    align-items: center;
    gap: 0.8em;
    width: 100%;
  }
  .footer-links {
    justify-content: space-around;
    width: 100%;
  }
}
@media (min-width: 500px) {
  .container.footer-container {
    max-width: 700px;
  }
}
@media (min-width: 1100px) {
  .container.footer-container {
    max-width: 1100px;
  }
}
@media (min-width: 900px) {
  .container.footer-container {
    max-width: 1000px;
  }
}

@media screen and (max-width: 550px) {
  .expert-teams-cards > div {
    margin-bottom: 9em;
  }
  .expert-teams-cards > div:last-child {
    margin-bottom: 6em;
  }
}

.checkmark {
  display: inline-block;
  width: 22px;
  height: 22px;
  -ms-transform: rotate(45deg); /* IE 9 */
  -webkit-transform: rotate(45deg); /* Chrome, Safari, Opera */
  transform: rotate(45deg);
}

.checkmark_stem__gray {
  position: absolute;
  width: 3px;
  height: 9px;
  background-color: #ccc;
  left: 11px;
  top: 6px;
}

.checkmark_kick__gray {
  position: absolute;
  width: 3px;
  height: 9px;
  background-color: #ccc;
  left: 11px;
  top: 6px;
  transform: rotate(90deg);
}

.checkmark_stem__green {
  position: absolute;
  width: 3px;
  height: 9px;
  background-color: #78ca49;
  left: 11px;
  top: 6px;
}

.checkmark_kick__green {
  position: absolute;
  width: 3px;
  height: 3px;
  background-color: #78ca49;
  left: 8px;
  top: 12px;
}
