@font-face {
  font-family: 'AvertaStdExtraBold';
  src: url('./files/AvertaStd-ExtraBold.ttf');
}
@font-face {
  font-family: 'AvertaStdSemiBold';
  src: url('./files/AvertaStd-Semibold.ttf');
}
@font-face {
  font-family: 'AvertaStdBold';
  src: url('./files/AvertaStd-Bold.ttf');
}
@font-face {
  font-family: 'AvertaStdRegular';
  src: url('./files/AvertaStd-Regular.ttf');
}
