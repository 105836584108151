@-webkit-keyframes float-mailToInternet-img {
  0% {
    transform: translatey(0px) translatex(0px);
  }
  50% {
    transform: translatey(-20px) translatex(12px);
  }
  100% {
    transform: translatey(0px) translatex(0px);
  }
}
@keyframes float-mailToInternet-img {
  0% {
    transform: translatey(0px) translatex(0px);
  }
  50% {
    transform: translatey(-12px) translatex(12px);
  }
  100% {
    transform: translatey(0px) translatex(0px);
  }
}

.mailToInternet-img {
  overflow: hidden;
  transform: translatey(0px);
  -webkit-animation: float-mailToInternet-img 3s ease-in-out infinite;
  animation: float-mailToInternet-img 3s ease-in-out infinite;
}

@-webkit-keyframes float-statistics1-img {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}
@keyframes float-statistics1-img {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-12px);
  }
  100% {
    transform: translatey(0px);
  }
}

.statistics1-img {
  overflow: hidden;
  transform: translatey(0px);
  -webkit-animation: float-statistics1-img 4s ease-in-out infinite;
  animation: float-statistics1-img 4s ease-in-out infinite;
}

@-webkit-keyframes float-statistics2-img {
  0% {
    transform: translatey(0px) translatex(0px);
  }
  50% {
    transform: translatey(-20px) translatex(-12px);
  }
  100% {
    transform: translatey(0px) translatex(0px);
  }
}
@keyframes float-statistics2-img {
  0% {
    transform: translatey(0px) translatex(0px);
  }
  50% {
    transform: translatey(-12px) translatex(-12px);
  }
  100% {
    transform: translatey(0px) translatex(0px);
  }
}
.statistics2-img {
  overflow: hidden;
  transform: translatey(0px);
  -webkit-animation: float-statistics2-img 3s ease-in-out infinite;
  animation: float-statistics2-img 3s ease-in-out infinite;
}

@-webkit-keyframes float-personMic-img {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-20px);
  }
  100% {
    transform: translatey(0px);
  }
}
@keyframes float-personMic-img {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-12px);
  }
  100% {
    transform: translatey(0px);
  }
}
.personMic-img {
  overflow: hidden;
  transform: translatey(0px);
  -webkit-animation: float-personMic-img 5s ease-in-out infinite;
  animation: float-personMic-img 5s ease-in-out infinite;
}

@-webkit-keyframes float-networking-img {
  0% {
    transform: translatey(0px) translatex(0px);
  }
  50% {
    transform: translatey(-20px) translatex(12px);
  }
  100% {
    transform: translatey(0px) translatex(0px);
  }
}
@keyframes float-networking-img {
  0% {
    transform: translatey(0px) translatex(0px);
  }
  50% {
    transform: translatey(-12px) translatex(12px);
  }
  100% {
    transform: translatey(0px) translatex(0px);
  }
}

.networking-img {
  overflow: hidden;
  transform: translatey(0px);
  -webkit-animation: float-networking-img 4s ease-in-out infinite;
  animation: float-networking-img 4s ease-in-out infinite;
}
